<template>
    <v-row>
        <v-col
            cols="12"
            sm="6"
        >
            <v-card
                    class="pa-2"
                    elevation="0"
            >
                <!--v-card-title></1--v-card-title-->
                <h2>{{header}}</h2>
                <v-alert type="error"
                         close-text="Закрыть сообщение"
                         dismissible
                         v-model="alertError.show"
                         @click="navBack()"
                >
                    {{alertError.text}}
                </v-alert>
                <v-alert type="warning"
                         close-text="Закрыть сообщение"
                         dismissible
                         v-model="alertWarning.show"
                         @click="navBack()"
                >
                    {{alertWarning.text}}
                </v-alert>
                <v-btn v-if="navBackShow" block
                       color="primary"
                       @click.prevent="navBack()"
                       elevation="10"
                >
                    Обратно
                </v-btn>
                <v-card
                        class="mt-6 pa-2"
                        elevation="10"
                        outlined
                        :loading="loading"
                >
                    <v-card-actions>
                        <v-container fluid>
                            <v-btn color="primary"
                                   class-del="mb-6 ml-3"
                                   class="ml-3"
                                   :disabled="!isvalid"
                                   @click.prevent="onNext()"
                                   :loading="loadingBtn"
                            >
                                {{captionNext}}

                            </v-btn>
                            <!--v-btn @click.prevent="fillTestData()"
                                   class-del="mb-6 ml-3"
                                   class="ml-3"
                            >
                                filltestdata
                            </v-btn-->

                        </v-container>
                    </v-card-actions>

                    <v-card-text>
                        <v-form v-model="isvalid">
                            <v-container fluid>
                                <v-row align="center">
                                    <v-col
                                            class="d-flex"
                                            cols="12"
                                            md="4"
                                    >
                                        <v-select
                                                ref="p_dd"
                                                v-model="p_dd"
                                                :items="arr_dd"
                                                item-text="nname"
                                                item-value="num"
                                                label="День рождения"
                                                placeholder="01"
                                        ></v-select>

                                    </v-col>
                                    <v-col
                                            class="d-flex"
                                            cols="12"
                                            md="4"
                                    >
                                        <v-select
                                                ref="p_mm"
                                                v-model="p_mm"
                                                :items="arr_mm"
                                                item-text="nname"
                                                item-value="num"
                                                label="Месяц"
                                                placeholder="Январь"
                                        ></v-select>
                                    </v-col>
                                    <v-col
                                            class="d-flex"
                                            cols="12"
                                            md="4"
                                    >
                                        <v-select
                                                ref="p_yyyy"
                                                v-model="p_yyyy"
                                                :items="arr_yyyy"
                                                item-text="nname"
                                                item-value="num"
                                                label="Год"
                                                placeholder="1966"
                                        ></v-select>
                                    </v-col>
                                </v-row>
                            </v-container >
                            <v-container fluid>
                                <v-row align="center">
                                    <v-col
                                            class="d-flex"
                                            cols="12"
                                            md="8"
                                    >
                                        <v-text-field
                                                ref="p_num"
                                                v-model="p_num"
                                                label="Номер полиса"
                                                placeholder="123456789012345"
                                                :rules="numRules"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-container>
                            <v-container fluid>
                                <v-checkbox
                                        v-model="p_agree"
                                        label="Согласен передать данные в обработку согласно ФЗ №152"
                                        :error="!p_agree"
                                ></v-checkbox>
                            </v-container>

                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-container fluid>
                        <v-btn color="primary"
                               class="mb-6 ml-3"
                               :disabled="!isvalid"
                               @click.prevent="onNext()"
                               :loading="loadingBtn"
                        >
                            {{captionNext}}
                        </v-btn>
                        <v-btn v-if="showBtnFilltestdata"
                               @click.prevent="fillTestData()"
                               class="mb-6 ml-3"
                        >
                            filltestdata
                        </v-btn>

                        </v-container>
                    </v-card-actions>
                </v-card>
            </v-card>
        </v-col>
        <v-col
                cols="12"
                sm="6"
        >
            <v-card
                    class="pa-2"
                    elevation="0"
            >
                <h2>Выбрано</h2>
                <v-card
                        class="mt-6 pa-2"
                        elevation="0"
                        outlined
                >


                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title v-html="LPU.name"/>
                            <v-list-item-subtitle v-text="LPU.address"/>
                        </v-list-item-content>
                    </v-list-item>
                </v-card>
            </v-card>
        </v-col>

    </v-row>


</template>>

<style scoped>
    .invalid  {
     color: red;
    }


</style>
<script>
    import post, {postLog} from '../tools/post'
    import {toBack, toNext, thisLog} from '@/components/app/js/globals'
    import {kardInit,doRest, kardClick, kardSave, kardColor} from "@/components/app/js/kard"; /*kard*/


    export default {
        name: 'Ccancel',
        data: () => ({
            name: 'Ccancel',
            header: 'Просмотр талонов', /*'Отмена приема',*/
            captionNext: 'Далее',
            isvalid: false,
            loading: false,
            loadingBtn: false,
            log: true,
            alertError: {
                show: false,
                text: null
            },
            alertWarning: {
                show: false,
                text: null
            },
            numRules:[
                v => !!v || 'Обязательно для заполнения',
                v => /^(\d+)$/.test(v) || 'Должны быть только цифры',
                v => (v && v.length >=7 && v.length <=16) || 'Количество цифр полиса должно быть от 7 до 16'
            ],
            p_dd:    '',
            p_mm:    '',
            p_yyyy:  '',
            p_num:   '',
            p_agree: false,
            arr_dd: [],
            arr_mm: [],
            arr_yyyy: []
        }),
        computed: {
            LPU() {
                return this.$store.getters.LPU;
            },
            KARD() {
                return this.$store.getters.KARD;
            },
            is_actual() {
                return (this.$store.getters.LPU.actual && this.$store.getters.LPU.actual==='1')
            },
            is_actualKARD() {
                return (this.$store.getters.KARD.actual && this.$store.getters.KARD.actual==='1')
            },
            navBackShow() {
                //console.log('-----',this.alertError.show, this.alertWarning.show)
                return (this.alertError.show || this.alertWarning.show)
            },
            showBtnFilltestdata(){
                return (process.env.NODE_ENV === 'development')
            }
        },
        beforeMount() {
            try {
                //console.log('ccancel beforemount')
                kardInit(this)
                doRest(this)
/*
                let year_b, year_e
                const curyear = new Date().getFullYear()
                year_b = 1900
                year_e = curyear

                //заполнть список дней
                for (let i = 0, dd = 1; dd <= 31; i++, dd++) {
                        this.arr_dd[i] = {num: (dd.toString().length<2?'0':'')+dd, nname: ''+dd, selected: false}
                }

                //заполнть список годов рождения
                for (let i = 0, yyyy = year_b; yyyy <= year_e; i++, yyyy++) {
                    this.arr_yyyy[i] = {num: ''+yyyy, nname: ''+yyyy, selected: false}
                }
                //console.log('beforeMount')
                //восстановить предыдущие значения
                this.doRest()
*/
                //актуальность nолько по onNext!!!
                this.$store.dispatch('SET_CCANCEL_ACTUAL', '0')

            }catch(e){
                alert(e)
                postLog(this.$store.getters.LPU.resturl, { alert: e, place: 'Ccancel#1' } )

            }


            //подтвердить актуальность selectedItem при переходах!!!
            //if(this.selectedItem && this.arr.length<this.selectedItem+1){
            //    this.$store.dispatch('SET_CCANCEL_ACTUAL', '0')
            //}

            this.loading=false
        },
        //mounted() {
        //    console.log('ccancel mounted')
        //},
        beforeDestroy() {
            //console.log('ccancel before destroy index')
            kardSave(this) /*kard*/
        },
        methods: {
            fillTestData() {
                this.p_dd='28'
                this.p_mm='03'
                this.p_yyyy='1966'
                this.p_num='7448110874000229'
            },
            doRest(){
                //if(this.is_actual) {
                    /*
                    this.p_dd = this.KARD.p_dd
                    this.p_mm = this.KARD.p_mm
                    this.p_yyyy = this.KARD.p_yyyy
                    this.p_num = this.KARD.p_num
                    this.p_agree = this.KARD.p_agree
                    */
                    //восстановить предыдущие значения
                    this.p_dd=localStorage.getItem('p_dd')
                    this.p_mm=localStorage.getItem('p_mm')
                    this.p_yyyy=localStorage.getItem('p_yyyy')
                    this.p_num=localStorage.getItem('p_num')
                    this.p_agree = localStorage.getItem('p_agree')==='true'
                    //console.log('p_dd <<<<< ',localStorage.getItem('p_dd'))

                //}
            },
            doSave(){
                try {
                    if(this.p_dd) {localStorage.setItem('p_dd',this.p_dd)}
                    if(this.p_mm) {localStorage.setItem('p_mm',this.p_mm)}
                    if(this.p_yyyy) {localStorage.setItem('p_yyyy',this.p_yyyy)}
                    if(this.p_num) {localStorage.setItem('p_num',this.p_num)}
                    if(this.p_agree) {localStorage.setItem('p_agree',this.p_agree)}
                    //console.log('p_dd >>>> ',this.p_dd)
                }catch(e){
                    alert(e)
                    postLog(this.$store.getters.LPU.resturl, { alert: e, place: 'Ccancel#2' } )
                }
            },
            async navBack(){
                toBack(this)
            },
            async onNext() {
                try {
                    if (this.is_actual) {

                        this.loadingBtn = true

                        const url = this.$store.getters.LPU.resturl + '/getfindkardnumdr'
                        thisLog(this, ['url', url])
                        const data = {
                            num: this.p_num,
                            dd: this.p_dd, //(this.p_dd.toString().length < 2) ? '0' + this.p_dd : '' + this.p_dd,
                            mm: this.p_mm, //(this.p_mm.toString().length < 2) ? '0' + this.p_mm : '' + this.p_mm,
                            yyyy: this.p_yyyy //'' + this.p_yyyy,
                        }

                        try {
                            let res = await post(url, data)
                            thisLog(this, [` CCANCEL >>>>>>>>> `, res])
                            if (res && res.data && res.data.length == 1) { //ответ получен
                                const el = res.data[0]
                                if (el.NPPKARD && el.NPPKARD.length > 0) {
                                    //if (el.NNAME && el.NNAME.length > 0) {
                                    //    pushNote(this, el.NNAME, {msg: true})
                                    //}
                                    let arr = {
                                        p_nppkard: el.NPPKARD,
                                        p_dd: this.p_dd,
                                        p_mm: this.p_mm,
                                        p_yyyy: this.p_yyyy,
                                        p_num: this.p_num
                                    }
                                    this.$store.dispatch('SET_CCANCEL', arr)
                                    this.doSave()

                                    await this.$router.push('/stacancel')
                                    //toNext(this)
                                } else {
                                    if (el.NNAME && el.NNAME.length > 0) {
                                        this.alertError.text = el.NNAME //описание ошибки
                                        this.alertError.show = true
                                        window.scrollTo(0, top)

                                        //pushNote(this, el.NNAME)
                                    }
                                }
                            } else {
                                this.alertWarning.text = "Что-то пошло не так"
                                this.alertWarning.show = true
                                window.scrollTo(0, top)

                                //this.navBack()  //каскадный откат
                                //pushNote(this, 'Что-то пошло не так')
                            }

                        } catch (err) {
                            this.alertError.text=err
                            this.alertError.show=true
                            window.scrollTo(0, top)

                        }

                        //this.loadingBtn = false
                    } else {
                        this.alertWarning.text = "Не найдено данных - повторите попытку еще раз"
                        this.alertWarning.show = true
                        window.scrollTo(0, top)

                        this.navBack()  //каскадный откат
                    }
                }catch(e){
                    alert(e)
                    postLog(this.$store.getters.LPU.resturl, { alert: e, place: 'Ccancel#3' } )
                }
                this.loadingBtn = false
            }
        }
    }
</script>
